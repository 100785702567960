export let lightTheme = {
  black: `20 20 20`,
  white: `255 255 255`,

  'trn-50': `#FAFAFA`,
  'trn-100': `#F5F5F5`,
  'trn-200': `#EBEBEB`,
  'trn-300': `#CCCCCC`,
  'trn-400': `#999999`,
  'trn-500': `#808080`,
  'trn-600': `#666666`,
  'trn-700': `#4D4D4D`,
  'trn-800': `#333333`,
  'trn-900': `#212121`,
  'trn-950': `#212121`,

  'red-50': `#fef2f2`,
  'red-100': `#fee2e2`,
  'red-200': `#fecaca`,
  'red-300': `#fca5a5`,
  'red-400': `248 113 113`,
  'red-500': `239 68 68`,
  'red-600': `200 38 38`,
  'red-700': `#b91c1c`,
  'red-800': `#991b1b`,
  'red-900': `#7f1d1d`,
  'red-950': `#450a0a`,

  'amber-50': `#fffbeb`,
  'amber-100': `#fef3c7`,
  'amber-200': `#fde68a`,
  'amber-300': `#fcd34d`,
  'amber-400': `#fbbf24`,
  'amber-500': `#f59e0b`,
  'amber-600': `#d97706`,
  'amber-700': `#b45309`,
  'amber-800': `#92400e`,
  'amber-900': `#78350f`,
  'amber-950': `#451a03`,

  'green-50': `#f0fdf4`,
  'green-100': `#dcfce7`,
  'green-200': `#bbf7d0`,
  'green-300': `#86efac`,
  'green-400': `74 222 128`,
  'green-500': `34 197 94`,
  'green-600': `22 163 74`,
  'green-700': `#15803d`,
  'green-800': `#166534`,
  'green-900': `#14532d`,
  'green-950': `#052e16`,

  'gray-50': `#f9fafb`,
  'gray-100': `#f3f4f6`,
  'gray-200': `#e5e7eb`,
  'gray-300': `#d1d5db`,
  'gray-400': `#9ca3af`,
  'gray-500': `#6b7280`,
  'gray-600': `#4b5563`,
  'gray-700': `#374151`,
  'gray-800': `#1f2937`,
  'gray-900': `#111827`,
  'gray-950': `#030712`,

  'neutral-50': `#fafafa`,
  'neutral-100': `#f5f5f5`,
  'neutral-200': `#e5e5e5`,
  'neutral-300': `#d4d4d4`,
  'neutral-400': `#a3a3a3`,
  'neutral-500': `#737373`,
  'neutral-600': `#525252`,
  'neutral-700': `#404040`,
  'neutral-800': `#262626`,
  'neutral-900': `#171717`,
  'neutral-950': `#0a0a0a`,

  'blue-50': `#eff6ff`,
  'blue-100': `#dbeafe`,
  'blue-200': `#bfdbfe`,
  'blue-300': `#93c5fd`,
  'blue-400': `96 165 250`,
  'blue-500': `59 130 246`,
  'blue-600': `37 99 235`,
  'blue-700': `#1d4ed8`,
  'blue-800': `#1e40af`,
  'blue-900': `#1e3a8a`,
  'blue-950': `#172554`,

  'yellow-50': `#fefce8`,
  'yellow-100': `#fef9c3`,
  'yellow-200': `#fef08a`,
  'yellow-300': `#fde047`,
  'yellow-400': `#facc15`,
  'yellow-500': `#eab308`,
  'yellow-600': `#ca8a04`,
  'yellow-700': `#a16207`,
  'yellow-800': `#854d0e`,
  'yellow-900': `#713f12`,
  'yellow-950': `#422006`,
}
